import * as React from "react";
import {css} from "@emotion/react";
import {map} from "lodash";
import {IFormFieldProps} from "@web2/form2";

import {FieldLabel} from "./FieldLabel";
import {FormErrorMessage} from "./FormErrorMessage";

export interface IRange<T> {
    lower: T;
    upper: T;
    bounds?: string;
}

export enum Direction {
    UP = "UP",
    DOWN = "DOWN"
}

export type RangeType = string;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ICreateRangeInnerComponentProps extends IFormFieldProps<string, any> {
    enableOnBlur?: boolean;
    roundingDirection?: Direction;
    roundToMultiplicationOf?: number;
    groupClassName?: string;
    placeholder?: string;
    type?: string;
    pattern?: string;
    maxNumberLength?: number;
    callAfterChangeOnEnter?: boolean;
    clearOnFocus?: boolean;
    onBlur: () => void;
}

export function createRange(InnerComponent: React.ComponentType<ICreateRangeInnerComponentProps>) {
    interface RangeProps extends IFormFieldProps<string, IRange<RangeType>> {
        label?: string;
        disabled?: boolean;
        className?: string;
        groupClassName?: string;
        options?: {value: number; label: string | undefined}[];
        hasDashSeparator?: boolean;
        errorOnBottom?: boolean;
        callAfterChangeOnEnter?: boolean;
        roundToMultiplicationOf?: number;
        enableOnBlur?: boolean;
        clearOnFocus?: boolean;
        onBlur: () => void;
    }

    const RangeFields = (props: RangeProps) => {
        const onChange = (name: string, value: RangeType) => {
            if (name === `${props.name}__lower`) {
                props.onChange(props.name, {...props.value, lower: value});
            }
            if (name === `${props.name}__upper`) {
                props.onChange(props.name, {...props.value, upper: value});
            }
        };

        const onAfterChange = (name: string, value: RangeType) => {
            if (name === `${props.name}__lower`) {
                props.onAfterChange(props.name, {...props.value, lower: value});
            }
            if (name === `${props.name}__upper`) {
                props.onAfterChange(props.name, {...props.value, upper: value});
            }
        };

        const renderOptions = (name: string, cutDirection = 0) => {
            const {value, options} = props;
            const isLower = cutDirection > 0;
            const isUpper = cutDirection < 0;

            let currentValue: RangeType | "";
            let oppositeFieldValue: RangeType | "";

            if (options && value && cutDirection !== 0) {
                if (isLower) {
                    currentValue = value.lower;
                    oppositeFieldValue = value.upper;
                } else {
                    currentValue = value.upper;
                    oppositeFieldValue = value.lower;
                }
            }

            return (
                <div>
                    {map(props.options || [], (option: {label: string; value: RangeType}) => {
                        let disabled = false;
                        let optionLabel: string | JSX.Element = option.label;
                        if ((currentValue as string | number).toString() === option.value.toString()) {
                            optionLabel = <b>{option.label}</b>;
                        } else {
                            const isAbove = isLower && oppositeFieldValue < option.value;
                            const isBelow = isUpper && oppositeFieldValue > option.value;
                            if (oppositeFieldValue !== "" && (isAbove || isBelow)) {
                                disabled = true;
                                optionLabel = <span>{option.label}</span>;
                            }
                        }
                        return (
                            <div key={option.value.toString()} onClick={() => (disabled ? null : onChange(name, option.value))}>
                                {optionLabel}
                            </div>
                        );
                    })}
                </div>
            );
        };

        const renderFields = (): JSX.Element => {
            const {name, value} = props;

            return (
                <div>
                    {props.error && !props.errorOnBottom && <FormErrorMessage error={props.error} />}
                    <div css={rangeHolder}>
                        <div css={inputHolder} className="range-input-lower">
                            <InnerComponent
                                name={`${name}__lower`}
                                value={value && value.lower}
                                error={undefined}
                                onChange={onChange}
                                onAfterChange={onAfterChange}
                                groupClassName={props.groupClassName}
                                placeholder={"Od"}
                                type="number"
                                pattern="[0-9]*"
                                maxNumberLength={8}
                                enableOnBlur={props.enableOnBlur}
                                roundToMultiplicationOf={props.roundToMultiplicationOf}
                                roundingDirection={Direction.DOWN}
                                callAfterChangeOnEnter={props.callAfterChangeOnEnter}
                                clearOnFocus={props.clearOnFocus}
                                onBlur={props.onBlur}
                            />
                        </div>
                        {renderOptions(`${name}__lower`, 1)}

                        {props.hasDashSeparator && <span>-</span>}

                        <div css={inputHolder} className="range-input-upper">
                            <InnerComponent
                                name={`${name}__upper`}
                                value={value && value.upper}
                                error={undefined}
                                onChange={onChange}
                                onAfterChange={onAfterChange}
                                groupClassName={props.groupClassName}
                                placeholder={"Do"}
                                type="number"
                                pattern="[0-9]*"
                                maxNumberLength={8}
                                enableOnBlur={props.enableOnBlur}
                                roundToMultiplicationOf={props.roundToMultiplicationOf}
                                roundingDirection={Direction.UP}
                                callAfterChangeOnEnter={props.callAfterChangeOnEnter}
                                clearOnFocus={props.clearOnFocus}
                                onBlur={props.onBlur}
                            />
                        </div>
                        {renderOptions(`${name}__upper`, -1)}
                    </div>
                    {props.error && props.errorOnBottom && <FormErrorMessage error={props.error} />}
                </div>
            );
        };

        const {label, name} = props;
        const fields = renderFields();

        return (
            <div>
                {label && <FieldLabel label={label} htmlFor={`${name}__lower`} />}
                {fields}
            </div>
        );
    };
    return RangeFields;
}

const rangeHolder = css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        margin-bottom: 1rem;
    }
`;

const inputHolder = css`
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 1rem;
    margin-left: 1rem;

    &.range-input-lower {
        margin-left: 0;
    }

    &.range-input-upper {
        margin-right: 0;
    }
`;
