import {find, uniq} from "lodash";
import {parseOfferSlugToObject} from "@web2/gh_routes";
import {capitalize, capitalizeFirst} from "@web2/nodash";
import {numberWithDelimiter, pluralize} from "@web2/string_utils";

import {IOfferDetailPrice} from "../../app/interfaces/response/offer_detail";
import {dealTypeToUrl} from "../../app/utils/deal_type_to_url";
import {getMappedLocationPath} from "../../app/utils/get_offer_location_obj_from_path";
import {floorFormat, numberWithComa, roundSafely, sizeFormat} from "../../app/utils/number";
import {individualOfferFormDefaultValues} from "../../individual_offers/components/IndividualOfferForm";
import {ILocation, ILocationOfferCount} from "../list/actions/fetch_location_by_slug_at_route";
import {getOfferListUrl} from "../list/url_utils/get_offer_list_url";
import {UrlTagSlug} from "../list/url_utils/tags";
import {ActiveModalGalleryTab} from "../types/GalleryTypes";
import {
    dealTypeVerbPL,
    flatPluralize,
    housePluralize,
    IOfferType,
    OfferDealType,
    OfferMarketType,
    OfferType,
    OfferTypeDeclensionWhat,
    OfferTypeEN,
    OfferTypePL,
    OfferTypeSinglePL,
    PropertyType,
    UOfferTypes
} from "./constants_offer";

import aftermarketMarkerIcon from "../../styles/assets/svg/aftermarket_marker.svg";
import educationIcon from "../../styles/assets/svg/education-marker.svg";
import markerIcon from "../../styles/assets/svg/gethome_pin.svg";
import primaryMarketMarketIcon from "../../styles/assets/svg/primary_market_marker.svg";
import shopsIcon from "../../styles/assets/svg/shops-marker.svg";
import transportIcon from "../../styles/assets/svg/transport-marker.svg";

interface IDistanceFromRegion {
    center: boolean;
    distance: number;
    region: string;
}

export function distanceFromRegion(data: IDistanceFromRegion): string {
    return `${numberWithComa(data.distance)}km od ${data.center ? "centrum" : ""} ${data.region}`;
}

export function isInLimitedInvestment(offer: any): boolean {
    return offer.investment ? offer.investment.limited_presentation : false;
}

export function offerPrefix(offer?: any): string {
    if (offer && offer.investment) {
        if (offer.investment.investment_type === "house") {
            return "Nowy";
        }

        if (offer.investment.investment_type === "apartment") {
            return "Nowe";
        }

        return "Nowa"; // Lot
    }

    return "";
}

//

export function translateOfferType(
    selectedOfferType: string,
    dictionary: typeof OfferType | typeof OfferTypePL | typeof OfferTypeEN | typeof OfferTypeSinglePL | typeof OfferTypeDeclensionWhat = OfferType
): UOfferTypes {
    let url;
    if (selectedOfferType === "lot" || selectedOfferType === "dzialki") {
        url = dictionary.lot;
    } else {
        const matchRex = new RegExp(/house|apartment|lot/g);
        const matches = uniq(selectedOfferType.match(matchRex));

        url =
            matches.length === 2 || matches.length === 0 || selectedOfferType.split(",").find((item: string) => !item.match(matchRex))
                ? dictionary.property
                : (dictionary as any)[matches[0]];
    }
    return url as UOfferTypes;
}

export const roomsPluralism = pluralize(["pokój", "pokoje", "pokoi"]);

export function pluralizedRoomsNumber(value?: number | null | undefined): string {
    return value ? `${value} ${roomsPluralism(value)}` : "";
}

const getCityAndOrDistrictFromLocation = (path: {name: string}[]) => {
    const district = find(path, ["type", "Dzielnica"]);
    const city = find(path, ["type", "Miasto"]) || find(path, ["type", "Miejscowość"]);
    if (city && district) {
        return ` ${city.name} ${district.name}`;
    }
    if (city) {
        return ` ${city.name}`;
    }

    const region = find(path, ["type", "Powiat"]);
    if (region) {
        return ` ${region.name}`;
    }

    const voivodeship = find(path, ["type", "Województwo"]);
    if (voivodeship) {
        return ` ${voivodeship.name}`;
    }

    return "";
};

export interface IOfferTitleWithLocation {
    deal_type: OfferDealType;
    market_type: OfferMarketType;
    offer_type?: string[];
    property: {
        floor?: number | null;
        group_lot_info?: {
            shape?: string | null;
        };
        location?: {
            path?: {
                name: string;
            }[];
        } | null;
        lot_type?: string | null;
        room_number?: number | null;
        rooms?: number | null;
        signature?: string | null;
        size: number | null;
        type: string;

        // TODO: remove after test
        lot_size?: number | null;
    };
    agency?: {
        name: string | null;
    } | null;
}

export interface IAgencyName {
    deal_type: OfferDealType;
    agency?: {
        name: string | null;
    } | null;
}

export const parseOfferLocation = (offer: IOfferTitle) => {
    const {property} = offer;

    const path = property.location?.path;
    const location = getMappedLocationPath(path);
    const {CITY: city, DISTRICT: district, COUNTY: county, ESTATE: estate, TOWN: town, VOIVODESHIP: voivodeship} = location;

    let parsedLocation = "";

    if (city?.name || town?.name) {
        parsedLocation += city?.name || town?.name || "";
        if (estate?.name || district?.name) {
            parsedLocation += ` ${estate?.name ? estate.name : district?.name}`;
        }
    } else {
        parsedLocation += county?.name ? `powiat ${county.name || ""}` : `województwo ${voivodeship?.name || ""}`;
    }

    return parsedLocation;
};

export function getOfferAgencyName(offer: IAgencyName): string | null {
    const titleOfferType = offer.deal_type == "sell" ? "Oferta sprzedaży" : "Oferta wynajmu";
    const agencyName = offer.agency ? ` od ${offer.agency.name}` : "";
    if (offer.agency && offer.agency?.name !== "") {
        return `${titleOfferType}${agencyName}`;
    }
    return null;
}

interface IOfferTitle {
    slug: string;
    deal_type: OfferDealType;
    market_type: OfferMarketType;
    offer_type?: string[];
    property: {
        group_lot_info?: {
            shape?: string | null;
        };
        lot_type?: string | null;
        room_number?: number | null;
        rooms?: number | null | undefined;
        signature?: string | null;
        size: number | string | null;
        type: string;
        location?: {
            path?: {name: string; slug: string; type: string}[];
            short_name: string;
        } | null;

        //TODO: remove after test
        terraces?: number | null;
        building_year?: number | null;
    };
    agency?: {
        name: string | null;
    } | null;
}

const parseAgencyName = (agency: {name: string | null} | null | undefined): string => {
    return agency && agency.name !== "" ? ` od ${agency.name}` : "";
};

export function getOfferTitle(offer: IOfferTitle): string {
    const prefix = offerPrefix(offer);
    const {offer_type, deal_type, property, agency, slug, market_type} = offer;
    const id = parseOfferSlugToObject(slug).offerId;
    const dealType = dealTypePL(deal_type);

    const parsedLocation = parseOfferLocation(offer);
    const offerType = offer_type ? translateOfferType(offer_type[1], OfferTypeSinglePL) : "";

    const {group_lot_info, lot_type, room_number, rooms, signature, size, type, terraces, building_year} = property;
    const propertySignature = market_type === OfferMarketType.PRIMARY_MARKET && signature ? `, nr ${signature}` : "";
    const propertySize = size ? `, ${sizeFormat(size)} m2` : "";

    // TODO: remove after test
    const isTerraces = type === PropertyType.APARTMENT && terraces !== null && terraces !== undefined && Number.isFinite(terraces) ? " z Tarasem" : "";
    const buildingYear = type === PropertyType.HOUSE && building_year ? ` z ${building_year}r` : "";

    if (type === PropertyType.LOT) {
        const lotType = lot_type === "Inna" ? "" : lot_type;
        const lotShape = group_lot_info?.shape ? `, ${group_lot_info.shape.toLowerCase()}` : "";

        return `${prefix} ${offerType}${lotType ? ` ${lotType.toLowerCase()}` : ""} ${dealType}${
            parsedLocation ? " " : ""
        }${parsedLocation}${propertySize}${lotShape}${parseAgencyName(agency)} |${id}|`.trim();
    }

    return `${prefix} ${prefix ? offerType : capitalize(offerType)} ${dealType} ${parsedLocation}, ${
        rooms ? pluralizedRoomsNumber(rooms) : room_number ? pluralizedRoomsNumber(room_number) : ""
    }${propertySize}${propertySignature}${isTerraces}${buildingYear}${parseAgencyName(agency)} |${id}|`.trim();
}

interface IOfferDescription extends IOfferTitle {
    offer_type: string[];
    price: {
        total: number | null;
    };
}

export function offerDescription(offer: IOfferDescription): string {
    const {deal_type, property, agency, slug} = offer;
    const id = parseOfferSlugToObject(slug).offerId;
    const dealType = dealTypePL(deal_type);
    const offerType = translateOfferType(offer.offer_type[1], OfferTypeDeclensionWhat);
    const parsedLocation = parseOfferLocation(offer);
    const parsedLocationString = parsedLocation ? ` w lokalizacji ${parsedLocation}` : ``;
    const {type, lot_type, size, rooms} = property;

    if (type === PropertyType.LOT) {
        // casting property because type was already checked
        const price = `${offer.price.total ? ` w cenie ${offer.price.total} zł` : ""}`;
        const lotType = (() => {
            // Do some declination, frontend side...
            const type = lot_type;
            if (type && type !== "Inna") {
                const lastLetter = type.slice(-1);
                if (lastLetter === "a") {
                    return (type.slice(0, -1) + "ej").toLowerCase();
                }
                return type;
            }
            return "";
        })();
        const lotSize = property.size;
        const shape = property.group_lot_info?.shape ? `kształt: ${property.group_lot_info.shape.toLowerCase()}` : "";
        return `Oferta działki ${lotType}${lotType ? " " : ""}${dealType}${parsedLocationString}, ${lotSize ? sizeFormat(lotSize) : ""} m2${price}${
            !shape ? "" : `, ${shape}`
        }${parseAgencyName(agency)} |${id}| - Sprawdź i ${dealTypeVerbPL(offer.deal_type)} na gethome.pl! ✅`;
    }

    return `Szukasz ${offerType} ${dealType}${parsedLocationString}? Sprawdź ofertę ${pluralizedRoomsNumber(rooms)}${
        size ? `, ${sizeFormat(size)} m2` : ""
    }${parseAgencyName(agency)} |${id}| - gethome.pl ✅`;
}

// if offer is for sale and has no given price per sqm
export function pricePerSqm(
    offer: {
        price: IOfferDetailPrice;
        property: {size: number | null};
        deal_type: OfferDealType;
    },
    radix = 2
) {
    const {
        price,
        property: {size},
        deal_type
    } = offer;

    if (deal_type === OfferDealType.SELL) {
        // check if need to count price per sqm
        const countPrice = !!(!price.per_sqm && price.total && size);

        if (countPrice && price.total && size) {
            return numberWithDelimiter(numberWithComa((price.total / roundSafely(size)).toFixed(radix)));
        }

        if (price.per_sqm) {
            return numberWithDelimiter(numberWithComa(price.per_sqm));
        }
    }
}

export function isForRent(deal_type: OfferDealType): boolean {
    return deal_type === OfferDealType.RENT;
}

export const poiMapIcon = (activeTab: ActiveModalGalleryTab | null) => {
    switch (activeTab) {
        case ActiveModalGalleryTab.EDUCATION_POI:
            return educationIcon;
        case ActiveModalGalleryTab.TRANSPORT_POI:
            return transportIcon;
        case ActiveModalGalleryTab.SHOPPING_POI:
            return shopsIcon;

        default:
            return markerIcon;
    }
};

export const poiOfferTypeIcon = (market_type: OfferMarketType) => {
    switch (market_type) {
        case "aftermarket":
            return aftermarketMarkerIcon;
        case "primary_market":
            return primaryMarketMarketIcon;
        default:
            return;
    }
};

export function dealTypePL(dealType?: OfferDealType) {
    switch (dealType) {
        case OfferDealType.RENT:
            return "do wynajęcia";

        case OfferDealType.SELL:
            return "na sprzedaż";

        default:
            return "na sprzedaż";
    }
}

export const parseDealType = (type: string | undefined) => {
    switch (type) {
        case OfferDealType.SELL:
            return "na sprzedaż";
        case OfferDealType.RENT:
            return "do wynajęcia";
        default:
            return "na sprzedaż i do wynajęcia";
    }
};

export const parseRoom = (rooms: number) => {
    switch (rooms) {
        case 1:
            return "one_room";
        case 2:
            return "two_rooms";
        case 3:
            return "three_rooms";
        case 4:
            return "four_rooms";
        case 5:
            return "five_rooms";
        default:
            return "";
    }
};

export const parseRooms = (rooms: number, isPlural?: boolean, isSingle?: boolean) => {
    if (isSingle) {
        switch (rooms) {
            case 1:
                return "kawalerki";
            case 2:
                return "dwupokojowego";
            case 3:
                return "trzypokojowego";
            case 4:
                return "czteropokojowego";
            case 5:
                return "pięciopokojowego";
            default:
                return "";
        }
    }

    if (isPlural) {
        switch (rooms) {
            case 1:
                return "kawalerek";
            case 2:
                return "dwupokojowych";
            case 3:
                return "trzypokojowych";
            case 4:
                return "czteropokojowych";
            case 5:
                return "pięciopokojowych";
            default:
                return "";
        }
    }

    switch (rooms) {
        case 1:
            return "kawalerki";
        case 2:
            return "dwupokojowe";
        case 3:
            return "trzypokojowe";
        case 4:
            return "czteropokojowe";
        case 5:
            return "pięciopokojowe";
        default:
            return "";
    }
};

export const getRecommendedName = (dealType: OfferDealType | undefined, offerType: string, tag: UrlTagSlug | undefined) => (text: string) => {
    if (dealType === undefined) {
        return `${capitalizeFirst(offerType)} ${text}`;
    }
    const type = dealTypePL(dealType);

    if (tag) {
        return `${capitalizeFirst(tag)} ${type} ${text}`;
    }

    return `${capitalizeFirst(offerType)} ${type} ${text}`;
};
export const getRecommendedUrl = (
    dealType: OfferDealType | undefined,
    offerType: UOfferTypes | OfferType,
    location: {location: string; subLocation?: string | undefined; subSubLocation?: string | undefined},
    tag?: UrlTagSlug
) => {
    const params = {
        offerType: offerType,
        location: location.location,
        subLocation: location.subLocation,
        subSubLocation: location.subSubLocation,
        ...(dealType ? {type: dealTypeToUrl(dealType)} : {}),
        ...(tag ? {tag} : {})
    };

    return getOfferListUrl(params);
};

export const dealTypeWrapper = (dealType: OfferDealType | undefined) => (text: string) => {
    const suffix = dealTypePL(dealType);

    if (dealType === "rent") {
        return `Wynajem ${text} gotowych ${suffix}`;
    }
    if (dealType === undefined) {
        return `${text}`;
    }

    return `${text} ${suffix}`;
};

export function getOfferTypePluralizedWithCount(offerType: string, count: number): string {
    switch (offerType) {
        case OfferType.house:
            return `${count} ${housePluralize(count)}`;

        case OfferType.apartment:
            return `${count} ${flatPluralize(count)}`;

        // nieruchomości
        default:
            return `${count} ${offerType}`;
    }
}

export function getOfferTypePluralized(offerType: string, count: number): string {
    switch (offerType) {
        case OfferType.house:
            return `${housePluralize(count)}`;

        case OfferType.apartment:
            return `${flatPluralize(count)}`;

        // nieruchomości
        default:
            return `${offerType}`;
    }
}

export const locationOfferCount =
    (offerType: IOfferType, dealType?: OfferDealType, tag?: UrlTagSlug) =>
    (location: ILocation): number => {
        const roomTag = tag === UrlTagSlug.STUDIO ? "_one_room" : tag === UrlTagSlug.TWO_ROOM ? "_two_rooms" : "";

        if (!dealType && offerType !== "property") {
            return (
                location.offer_count[`${offerType}${roomTag}_sell` as keyof ILocationOfferCount] +
                location.offer_count[`${offerType}${roomTag}_rent` as keyof ILocationOfferCount]
            );
        }

        if (dealType && offerType === "property") {
            return location.offer_count[dealType];
        }

        if (!dealType && offerType === "property") {
            return location.offer_count.rent + location.offer_count.sell;
        }

        return location.offer_count[`${offerType}${roomTag}_${dealType}` as keyof ILocationOfferCount];
    };

export function locationToParams(loc: ILocation): {location: string; subLocation?: string; subSubLocation?: string} {
    const {slug} = loc;

    const parts = slug.split("/");

    return {
        location: parts[0],
        subLocation: parts[1],
        subSubLocation: parts[2]
    };
}

export const roomsHeightRange = (min: number, max: number) => {
    if (min === max) {
        return `${min} cm wysokości`;
    }

    return `od ${min} do ${max} cm wysokości`;
};

export interface IGetOfferTitle {
    prefix: string;
    deal_type: string;
    propertySignature: string;
    offer_type: string;
    lot_type?: string | null;
    lot_shape?: string | null;
    property_size: string;
    titleLocation: string;
    lotSize: string;
    propertyFloor?: string | null;

    room_number?: number | null;
    rooms?: number | null;
}

export function getPreviewTitle(offer: typeof individualOfferFormDefaultValues): string {
    const prefix = offerPrefix(offer);
    const {lot_shape, locationCity, locationDistrict, type_of_property, rooms, size, offer_type, floor, lot_size, deal_type} = offer;
    const offerType = offer_type === "mieszkania" ? OfferTypeSinglePL.apartment : offer_type === "domy" ? OfferTypeSinglePL.house : OfferTypeSinglePL.lot;

    const dealType = dealTypePL(deal_type);
    const propertySize = size ? `, ${sizeFormat(size)} m2` : "";
    const titleLocation = locationDistrict ? `${locationCity} ${locationDistrict}` : locationCity;
    const propertyFloor =
        floor !== null && floor !== undefined && offer_type == "mieszkania" && Number.isFinite(Number(floor)) ? `, ${floorFormat(floor)}` : "";

    const lotSize = lot_size !== null && lot_size !== undefined && Number.isFinite(lot_size) ? `, działka ${sizeFormat(lot_size)} m2` : "";

    const titleData = {
        prefix: prefix,
        deal_type: dealType,
        offer_type: offerType,
        propertySignature: "",
        property_size: propertySize,
        titleLocation: titleLocation,
        rooms: rooms,
        lotSize: lotSize,
        lot_type: type_of_property === "inna" ? "" : type_of_property,
        lot_shape: lot_shape ? lot_shape : "",
        propertyFloor: propertyFloor
    };

    return getOfferTitleString(titleData);
}

const getOfferTitleString = (titleData: IGetOfferTitle) => {
    const {prefix, lotSize, property_size, titleLocation, propertyFloor, room_number, rooms, offer_type, propertySignature, deal_type} = titleData;
    if (offer_type === OfferTypeSinglePL.lot) {
        return `${prefix} ${offer_type}${titleData.lot_type ? ` ${titleData.lot_type.toLowerCase()}` : ""} ${deal_type} ${titleLocation}${property_size} ${
            titleData.lot_shape
        }`.trim();
    }

    return `${prefix} ${prefix ? offer_type : capitalize(offer_type)} ${deal_type} ${
        rooms ? pluralizedRoomsNumber(rooms) : room_number ? pluralizedRoomsNumber(room_number) : ""
    } ${titleLocation}${property_size}${propertyFloor}${lotSize}${propertySignature}`.trim();
};

export function getOfferTitleWithLocation(offer: IOfferTitleWithLocation): string {
    const prefix = offerPrefix(offer);
    const {market_type, offer_type, deal_type, property} = offer;
    const {group_lot_info, location, lot_type, room_number, rooms, signature, size, type, floor, lot_size} = property;
    const offerType = offer_type ? translateOfferType(offer_type[1], OfferTypeSinglePL) : "";
    const dealType = dealTypePL(deal_type);
    const propertySignature = market_type === OfferMarketType.PRIMARY_MARKET && signature ? ` - nr ${signature}` : "";
    const propertySize = size ? `, ${sizeFormat(size)} m2` : "";
    const titleLocation = location && location.path ? getCityAndOrDistrictFromLocation(location.path) : "";
    const propertyFloor = floor !== null && floor !== undefined && Number.isFinite(floor) ? `, ${floorFormat(floor)}` : "";

    // TODO: remove after test
    const lotSize = lot_size !== null && lot_size !== undefined && Number.isFinite(lot_size) ? `, działka ${sizeFormat(lot_size)} m2` : "";

    const titleData = {
        prefix: prefix,
        deal_type: dealType,
        offer_type: offerType,
        propertySignature: propertySignature,
        property_size: propertySize,
        titleLocation: titleLocation,
        rooms: rooms,
        room_number: room_number,
        lotSize: lotSize,
        lot_type: lot_type === "inna" ? "" : lot_type,
        lot_shape: group_lot_info?.shape ? group_lot_info.shape : "",
        propertyFloor: propertyFloor
    };

    return getOfferTitleString(titleData);
}

export const getCurrency = (currency: string | null | undefined): string => {
    if (currency) {
        return currency && currency !== "pln" ? currency.toUpperCase() : "zł";
    }
    return "zł";
};
