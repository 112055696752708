import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    mainColor?: string;
    accentColor?: string;
}

export const LandAreaIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "10.58mm"} height={props.height || "10.58mm"} viewBox="0 0 30 30">
            <path d="M29,20.11a1,1,0,0,0-1,1V28H21.1a1,1,0,1,0,0,2H29a1,1,0,0,0,1-1V21.1A1,1,0,0,0,29,20.11Z" fill={props.mainColor || "#37474f"} />
            <path d="M8.9,28H2V21.1a1,1,0,0,0-1-1,1,1,0,0,0-1,1V29a1,1,0,0,0,1,1H8.9a1,1,0,0,0,0-2Z" fill={props.mainColor || "#37474f"} />
            <path d="M1,9.89a1,1,0,0,0,1-1V2H8.9a1,1,0,0,0,1-1,1,1,0,0,0-1-1H1A1,1,0,0,0,0,1V8.9A1,1,0,0,0,1,9.89Z" fill={props.mainColor || "#37474f"} />
            <path d="M29,0H21.1a1,1,0,0,0-1,1,1,1,0,0,0,1,1H28V8.9a1,1,0,1,0,2,0V1A1,1,0,0,0,29,0Z" fill={props.mainColor || "#37474f"} />
            <path
                d="M15,6.55l-.07,0L5.61,13.76a1,1,0,0,0-.18,1.38,1,1,0,0,0,.79.39,1,1,0,0,0,.6-.21l.86-.67v9a1,1,0,0,0,.29.7,1,1,0,0,0,.7.29H22.33a1,1,0,0,0,1-1v-9l.86.66a1,1,0,0,0,.6.21,1,1,0,0,0,.79-.39,1,1,0,0,0-.18-1.38L16.1,6.58l-.06,0a1.51,1.51,0,0,0-.18-.09,1.15,1.15,0,0,0-.17-.06h-.38a1.15,1.15,0,0,0-.17.06A1.51,1.51,0,0,0,15,6.55Zm6.38,16.13H9.66V13.12L15.5,8.61l5.84,4.52Z"
                fill={props.accentColor || "#8b6aac"}
            />
        </svg>
    );
};
