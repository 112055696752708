import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const ShareIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path d="M7.88,7.08a1.49,1.49,0,0,0-1,.38l-3.4-2A1.42,1.42,0,0,0,3.57,5a1.42,1.42,0,0,0-.08-.41l3.4-2a1.5,1.5,0,0,0,.88.38,1.52,1.52,0,0,0,.93-.25,1.38,1.38,0,0,0,.56-.76A1.36,1.36,0,0,0,9.2,1,1.39,1.39,0,0,0,8.54.31a1.44,1.44,0,0,0-1-.13,1.47,1.47,0,0,0-.82.48,1.36,1.36,0,0,0-.32.88A1.41,1.41,0,0,0,6.51,2L3.12,4a1.47,1.47,0,0,0-.74-.36,1.44,1.44,0,0,0-.82.09,1.46,1.46,0,0,0-.64.51A1.38,1.38,0,0,0,.69,5a1.42,1.42,0,0,0,.23.77,1.53,1.53,0,0,0,.64.51,1.63,1.63,0,0,0,.82.09A1.49,1.49,0,0,0,3.12,6L6.51,8.05a1.54,1.54,0,0,0-.07.42,1.29,1.29,0,0,0,.24.76,1.37,1.37,0,0,0,.65.51,1.39,1.39,0,0,0,.83.08,1.36,1.36,0,0,0,.73-.38,1.42,1.42,0,0,0,.4-.7,1.45,1.45,0,0,0-.08-.8,1.48,1.48,0,0,0-.53-.63A1.52,1.52,0,0,0,7.88,7.08Zm0-6.23a.8.8,0,0,1,.4.11.77.77,0,0,1,.26.31.64.64,0,0,1,0,.41A.71.71,0,0,1,8.39,2,.73.73,0,0,1,8,2.22a.75.75,0,0,1-.42,0,.68.68,0,0,1-.32-.26.64.64,0,0,1-.12-.38.67.67,0,0,1,.21-.49A.73.73,0,0,1,7.88.85ZM2.13,5.7a.81.81,0,0,1-.4-.12.8.8,0,0,1-.27-.31.79.79,0,0,1,0-.4.72.72,0,0,1,.2-.36A.82.82,0,0,1,2,4.32a.81.81,0,0,1,.41,0,.77.77,0,0,1,.33.26A.74.74,0,0,1,2.85,5a.69.69,0,0,1-.21.49A.75.75,0,0,1,2.13,5.7ZM7.88,9.16A.72.72,0,0,1,7.48,9a.74.74,0,0,1-.27-.31A.66.66,0,0,1,7.37,8a.66.66,0,0,1,.37-.19.64.64,0,0,1,.41,0,.67.67,0,0,1,.45.64A.67.67,0,0,1,8.39,9,.75.75,0,0,1,7.88,9.16Z" />
        </SvgIcon>
    );
};
