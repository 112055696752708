import {appPath} from "@web2/gh_routes";

import {apiLink} from "../routes/api_link";
import {appendQueryString} from "../utils/append_query_string";
import {isServer} from "../utils/read_environment_variables";

export const loginClientViaRedirect = (hash?: string) => {
    if (isServer) throw new Error("loginClientViaRedirect use in server environment is forbidden");

    window.location.href = appendQueryString(apiLink.userApi.oauth.authorize(), {
        next: `${location.pathname}${hash ? hash : ""}`,
        redirect_uri: `${location.origin}${appPath.oauth.redirect}`
    });
};
