import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const SpaceIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "8.39mm"} height={props.height || "9.6mm"} viewBox="0 0 23.78 27.22">
            <polygon points="0 0 23.78 0 23.78 27.23 0 27.23 0 0" fill="#fff" fillRule="evenodd" />
            <path
                d="M1.4,7.65,11.21,2l0,11.28L1.43,18.93Zm20.45,12.4L12,25.72,2.29,20.06l9.81-5.67Zm-5.79-5V10.79l1.67,1V16Zm-1.38-6.7,0,5.88-2-1.17,0-11.25,9.72,5.65,0,11.25-3.24-1.88V10.94Zm8.7-1.91L12.45.1a.81.81,0,0,0-.77,0L.37,6.63A.74.74,0,0,0,0,7.27L0,20a.73.73,0,0,0,.36.63l11.27,6.55a.73.73,0,0,0,.73,0l11-6.37a.73.73,0,0,0,.36-.63l0-13a.73.73,0,0,0-.36-.63Z"
                fill={props.mainColor || "#37474f"}
                fillRule="evenodd"
            />
            <path
                d="M8.42,11.45l-.86.49v-4l.85-.49ZM6.16,8.75v4l-1.22.71,0-4Zm-2.64.09,0,6.51a.31.31,0,0,0,.46.26l5.67-3.27a.29.29,0,0,0,.15-.27V5.56a.31.31,0,0,0-.47-.26L3.68,8.57a.3.3,0,0,0-.16.27Z"
                fill={props.accentColor || "#8b6aac"}
                fillRule="evenodd"
            />
        </svg>
    );
};
