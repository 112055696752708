import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const ParkingIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "7.87mm"} height={props.height || "7.87mm"} viewBox="0 0 22.32 22.32">
            <path
                d="M18.9,0H3.42A3.43,3.43,0,0,0,0,3.42V18.89a3.43,3.43,0,0,0,3.42,3.43H15.47a.75.75,0,0,0,0-1.5h-12A1.92,1.92,0,0,1,1.5,18.89V3.42A1.92,1.92,0,0,1,3.42,1.5H18.9a1.92,1.92,0,0,1,1.92,1.92V15.48a.75.75,0,0,0,1.5,0V3.42A3.43,3.43,0,0,0,18.9,0Z"
                fill={props.mainColor || "#37474f"}
            />
            <path
                d="M7.42,6V17.25H8.87V12.71H11a4.18,4.18,0,0,0,2.87-.89,3.15,3.15,0,0,0,1-2.47,3.25,3.25,0,0,0-1-2.48A4.11,4.11,0,0,0,11,6Zm5.95,3.38a2.08,2.08,0,0,1-.58,1.58,2.74,2.74,0,0,1-1.92.56h-2V7.19h2a2.74,2.74,0,0,1,1.92.55A2.1,2.1,0,0,1,13.37,9.35Z"
                fill={props.accentColor || "#8b6aac"}
            />
        </svg>
    );
};
