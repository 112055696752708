import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const PriceTagSQMIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "9.81mm"} height={props.height || "11.62mm"} viewBox="0 0 27.81 32.94">
            <path
                d="M27.68,4.69a.91.91,0,0,0-.95-.9l-12.95.7a.88.88,0,0,0-.62.29l-1.37,1.5a.9.9,0,0,0,.05,1.28.91.91,0,0,0,1.28-.06l1.13-1.23,11.63-.62L26,17.58,13.88,30.75a1.15,1.15,0,0,1-1.65.05l-10-9.63a1.25,1.25,0,0,1,0-1.74l7.36-8a.9.9,0,0,0-.05-1.28.91.91,0,0,0-1.28,0l-7.37,8a3.07,3.07,0,0,0,.13,4.27L11,32.11A3,3,0,0,0,15.21,32L27.57,18.54a.89.89,0,0,0,.24-.62Z"
                fill={props.mainColor || "#37474f"}
            />
            <path
                d="M18.11,13.2l-.23,0a2.9,2.9,0,0,0,.48.58A3.26,3.26,0,0,0,23,13.66,3.37,3.37,0,0,0,22.88,9a3.24,3.24,0,0,0-4.66.15,3.35,3.35,0,0,0-.89,2.35c-3.48.24-6.39-1.52-6.69-4S13,2.37,16.72,1.89A7.73,7.73,0,0,1,22.39,3.2.92.92,0,0,0,23.66,3a.91.91,0,0,0-.17-1.27,9.54,9.54,0,0,0-7-1.68c-4.62.6-8.09,4-7.64,7.64S13.49,13.79,18.11,13.2Zm1.44-2.87a1.44,1.44,0,0,1,2.08-.07,1.57,1.57,0,0,1,.06,2.18,1.43,1.43,0,0,1-2.07.07A1.56,1.56,0,0,1,19.55,10.33Z"
                fill={props.mainColor || "#37474f"}
                opacity="0.13"
            />
            <path
                d="M13.79,23.43a.66.66,0,0,0,.49.2.63.63,0,0,0,.5-.2.94.94,0,0,0,.18-.62V20.25a2.82,2.82,0,0,0-.16-1.12,1.34,1.34,0,0,0-.59-.61,1.89,1.89,0,0,0-.92-.21,1.79,1.79,0,0,0-.84.19,2.33,2.33,0,0,0-.72.6,1.5,1.5,0,0,0-.58-.59,1.75,1.75,0,0,0-.85-.2,1.92,1.92,0,0,0-.87.19,2.36,2.36,0,0,0-.73.6V19a.68.68,0,0,0-.18-.5.61.61,0,0,0-.45-.18.58.58,0,0,0-.45.19.85.85,0,0,0-.17.56v3.76a.94.94,0,0,0,.19.62.7.7,0,0,0,1,0,.94.94,0,0,0,.18-.62V21A3,3,0,0,1,9,19.77a.83.83,0,0,1,.85-.45.58.58,0,0,1,.47.18.83.83,0,0,1,.18.45,7.51,7.51,0,0,1,0,.77v2.09a.89.89,0,0,0,.19.62.59.59,0,0,0,.48.2.63.63,0,0,0,.5-.2.94.94,0,0,0,.18-.62V21.06A3.15,3.15,0,0,1,12,19.89a1,1,0,0,1,.36-.41.84.84,0,0,1,.49-.16.59.59,0,0,1,.48.18.75.75,0,0,1,.2.42,6.13,6.13,0,0,1,0,.68v2.21A.94.94,0,0,0,13.79,23.43Z"
                fill={props.accentColor || "#8b6aac"}
            />
            <path
                d="M18,17a2.73,2.73,0,0,1-.47.4,6.47,6.47,0,0,0-.53.45c-.24.23-.52.5-.82.81a1.36,1.36,0,0,0-.21.32.91.91,0,0,0-.09.33.46.46,0,0,0,.15.34.56.56,0,0,0,.4.14H19a.45.45,0,0,0,.34-.12.39.39,0,0,0,.11-.28.36.36,0,0,0-.14-.3.69.69,0,0,0-.41-.11h-1.8a2.46,2.46,0,0,1,.17-.22,5.51,5.51,0,0,1,.71-.66c.35-.28.59-.49.74-.62a2.34,2.34,0,0,0,.43-.55,1.46,1.46,0,0,0,.21-.77,1.39,1.39,0,0,0-.1-.53,1.44,1.44,0,0,0-.28-.44,1.35,1.35,0,0,0-.43-.31,2.15,2.15,0,0,0-.89-.16,2,2,0,0,0-.76.13,1.47,1.47,0,0,0-.54.34,1.42,1.42,0,0,0-.32.46,1.37,1.37,0,0,0-.11.5.41.41,0,0,0,.12.31.39.39,0,0,0,.28.11.38.38,0,0,0,.3-.12,1,1,0,0,0,.18-.36l.12-.28a.81.81,0,0,1,.71-.39.86.86,0,0,1,.39.09.84.84,0,0,1,.29.27.77.77,0,0,1,.1.4.91.91,0,0,1-.1.41A1.5,1.5,0,0,1,18,17Z"
                fill={props.accentColor || "#8b6aac"}
            />
        </svg>
    );
};
