import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    mainColor?: string;
    accentColor?: string;
}

export const YearOfConstructionIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "10.49mm"} height={props.height || "10.39mm"} viewBox="0 0 29.74 29.45">
            <path d="M6,12.82a4.33,4.33,0,0,0-3.51,1.8h3.1a2.42,2.42,0,1,1,0,4.83H2.26A4.35,4.35,0,1,0,6,12.82Z" fill="none" />
            <rect x="20.5" y="3.94" width="0.68" height="2.18" fill="none" />
            <polygon points="24.05 1.57 22.75 1.57 22.75 3.74 22.75 6.31 22.75 8.49 24.05 8.49 24.05 1.57" fill="none" />
            <rect x="11.88" y="15.91" width="1.35" height="2.25" fill="none" />
            <path d="M27,18.16a1.13,1.13,0,1,0,0-2.25H18.62v2.25Z" fill="none" />
            <path d="M18.93,8.08V2.83a.6.6,0,0,0-.6-.6H12.4L9.07,4.67h3.86V8.08a.6.6,0,0,0,.6.6h4.8A.6.6,0,0,0,18.93,8.08Z" fill="none" />
            <path d="M14.8,26.75a1.13,1.13,0,1,0,2.25,0V10.29H14.8Z" fill="none" />
            <path
                d="M11.88,18.16V15.91h1.35V14.34H11.16a5.91,5.91,0,0,0-10.75.79L0,16.19H5.55a.85.85,0,1,1,0,1.69H0l.31,1A5.87,5.87,0,0,0,6,23.1a5.94,5.94,0,0,0,5.34-3.37h1.93V18.16ZM6,21.53a4.31,4.31,0,0,1-3.7-2.08H5.55a2.42,2.42,0,1,0,0-4.83H2.45A4.35,4.35,0,1,1,6,21.53Z"
                fill={props.accentColor || "#8b6aac"}
            />
            <path d="M27,14.34H18.62v1.57H27a1.13,1.13,0,1,1,0,2.25H18.62v1.57H27a2.7,2.7,0,1,0,0-5.39Z" fill={props.mainColor || "#8b6aac"} />
            <path
                d="M18.62,15.91v-5.7A2.16,2.16,0,0,0,20.5,8.08V7.69h.68v1a1.38,1.38,0,0,0,1.37,1.38h1.7a1.37,1.37,0,0,0,1.37-1.38V1.38A1.37,1.37,0,0,0,24.25,0h-1.7a1.38,1.38,0,0,0-1.37,1.38v1h-.74A2.16,2.16,0,0,0,18.33.66H11.89L7.26,4A1.22,1.22,0,0,0,8,6.24h3.38V8.08a2.16,2.16,0,0,0,1.87,2.13V26.75a2.7,2.7,0,1,0,5.39,0V15.91Zm4.13-9.6V1.57h1.3V8.49h-1.3ZM20.5,3.94h.68V6.12H20.5ZM17.05,26.75a1.13,1.13,0,1,1-2.25,0V10.29h2.25ZM18.33,8.68h-4.8a.6.6,0,0,1-.6-.6V4.67H9.07L12.4,2.23h5.93a.6.6,0,0,1,.6.6V8.08A.6.6,0,0,1,18.33,8.68Z"
                fill={props.mainColor || "#37474f"}
            />
        </svg>
    );
};
