import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const AreaIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "9.44mm"} height={props.height || "9.43mm"} viewBox="0 0 26.77 26.72">
            <path d="M15.93,21.82h4.88a.75.75,0,0,0,.75-.75V16.21a.75.75,0,0,0-1.5,0v4.11H15.93a.75.75,0,0,0,0,1.5Z" fill={props.accentColor || "#8b6aac"} />
            <path
                d="M5.66,15.46a.76.76,0,0,0-.75.75v4.86a.75.75,0,0,0,.75.75h4.88a.75.75,0,0,0,0-1.5H6.41V16.21A.75.75,0,0,0,5.66,15.46Z"
                fill={props.accentColor || "#8b6aac"}
            />
            <path d="M4.91,6v4.86a.75.75,0,0,0,1.5,0V6.73h4.13a.75.75,0,0,0,0-1.5H5.66A.75.75,0,0,0,4.91,6Z" fill={props.accentColor || "#8b6aac"} />
            <path d="M15.93,6.73h4.13v4.11a.75.75,0,0,0,1.5,0V6a.75.75,0,0,0-.75-.75H15.93a.75.75,0,0,0,0,1.5Z" fill={props.accentColor || "#8b6aac"} />
            <path
                d="M26.77,23.45a.61.61,0,0,0,0-.13.8.8,0,0,0,0-.15h0a.71.71,0,0,0-.15-.22,0,0,0,0,0,0,0h0L24,20.4a.75.75,0,0,0-1.06,0,.77.77,0,0,0,0,1.07L24.2,22.7H4V2.56L5.28,3.8A.71.71,0,0,0,5.81,4a.75.75,0,0,0,.52-1.28L3.81.22h0l-.06,0h0L3.57.06h0L3.43,0a.8.8,0,0,0-.15,0L3.17,0A.58.58,0,0,0,3,.06H3l-.05,0h0a.66.66,0,0,0-.17.12L.22,2.74a.75.75,0,0,0,0,1.06.75.75,0,0,0,1.06,0L2.53,2.56V23.45a.74.74,0,0,0,.75.75H24.2L23,25.44a.75.75,0,0,0,0,1.06.73.73,0,0,0,.53.22A.74.74,0,0,0,24,26.5L26.55,24h0s0,0,0,0a1.07,1.07,0,0,0,.15-.22h0a.8.8,0,0,0,0-.15.7.7,0,0,0,0-.14Z"
                fill={props.mainColor || "#37474f"}
            />
        </svg>
    );
};
