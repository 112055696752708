import * as React from "react";
import classNames from "classnames";

import {IIcon} from "../../IIcon";
import {SvgIcon} from "./SvgIcon";

export const EyeIcon = (props: IIcon) => {
    const {fillColor, size, wrapperColor, wrapperSize} = props;
    const className = classNames(props.className);

    return (
        <SvgIcon size={size || "1"} className={className} fillColor={fillColor} wrapperColor={wrapperColor} wrapperSize={wrapperSize}>
            <path d="M9.78 4.7C9.73 4.64 9.23 4.01 8.41 3.36C7.92 2.97 7.41 2.65 6.91 2.43C6.38 2.19 5.87 2.06 5.35 2.02C5.24 2.01 5.12 2 5 2C4.88 2 4.77 2 4.66 2.02C4.14 2.06 3.63 2.2 3.1 2.43C2.6 2.65 2.1 2.97 1.6 3.36C0.75 4.04 0.24 4.7 0.23 4.7L0 4.99L0.23 5.28C0.23 5.28 0.75 5.95 1.6 6.63C2.09 7.02 2.6 7.33 3.1 7.56C3.74 7.85 4.38 8 5.01 8C5.63 8 6.27 7.85 6.92 7.56C7.42 7.33 7.92 7.02 8.42 6.63C9.27 5.95 9.78 5.29 9.79 5.29L10.02 5L9.79 4.71L9.78 4.7ZM5.3 7.49C5.1 7.51 4.91 7.51 4.71 7.49C3.47 7.34 2.5 6.28 2.5 4.99C2.5 3.7 3.47 2.64 4.71 2.49C4.9 2.47 5.1 2.47 5.29 2.49C6.53 2.64 7.5 3.7 7.5 4.99C7.5 6.28 6.53 7.34 5.29 7.49H5.3ZM0.62 4.99C0.62 4.99 1.11 4.36 1.9 3.73C2.12 3.55 2.35 3.39 2.57 3.25C2.22 3.74 2.02 4.34 2.02 4.98C2.02 5.62 2.23 6.23 2.58 6.72C2.36 6.58 2.13 6.42 1.91 6.24C1.14 5.63 0.67 5.04 0.63 4.98L0.62 4.99ZM8.11 6.25C7.89 6.43 7.66 6.59 7.44 6.73C7.79 6.24 8 5.64 8 4.99C8 4.34 7.79 3.75 7.45 3.26C7.67 3.4 7.9 3.56 8.12 3.74C8.91 4.37 9.39 4.98 9.4 5C9.39 5.02 8.91 5.63 8.12 6.26L8.11 6.25Z" />
            <path d="M5.00998 3.95996C4.43998 3.95996 3.97998 4.41996 3.97998 4.98996C3.97998 5.55996 4.43998 6.01996 5.00998 6.01996C5.57998 6.01996 6.03998 5.55996 6.03998 4.98996C6.03998 4.41996 5.57998 3.95996 5.00998 3.95996V3.95996Z" />
        </SvgIcon>
    );
};
