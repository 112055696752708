import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const FinishingIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "7.23mm"} height={props.height || "8.01mm"} viewBox="0 0 20.49 22.71">
            <path
                d="M18.74,3.25H17v1.5h1.74A.25.25,0,0,1,19,5v7a.25.25,0,0,1-.25.25H10A1.76,1.76,0,0,0,8.25,14v0A2.49,2.49,0,0,0,6,16.5v3.71a2.5,2.5,0,0,0,5,0V16.5a2.49,2.49,0,0,0-1.25-2.15V14a.25.25,0,0,1,.25-.25h8.74A1.75,1.75,0,0,0,20.49,12V5A1.75,1.75,0,0,0,18.74,3.25Zm-9.24,17a1,1,0,0,1-2,0V16.5a1,1,0,0,1,2,0Z"
                fill={props.mainColor || "#37474f"}
            />
            <path d="M17,2a2,2,0,0,0-2-2H2A2,2,0,0,0,0,2V6A2,2,0,0,0,2,8H15a2,2,0,0,0,2-2V2Z" fill={props.accentColor || "#8b6aac"} />
        </svg>
    );
};
