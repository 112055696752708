import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const CalendarIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "8.65mm"} height={props.height || "9.21mm"} viewBox="0 0 24.52 26.12">
            <path d="M7.31,4.3h9.77a.75.75,0,0,0,0-1.5H7.31a.75.75,0,0,0,0,1.5Z" fill={props.mainColor || "#37474f"} />
            <path
                d="M23.77,2.8H20.54v-2a.75.75,0,0,0-1.5,0V5.29a.75.75,0,0,0,1.5,0v-1H23v4H1.5v-4H4v1a.75.75,0,1,0,1.5,0V.75A.75.75,0,0,0,4,.75V2.8H.75A.76.76,0,0,0,0,3.55V22a4.15,4.15,0,0,0,4.15,4.15H20.37A4.16,4.16,0,0,0,24.52,22V3.55A.76.76,0,0,0,23.77,2.8Zm-3.4,21.82H4.15A2.65,2.65,0,0,1,1.5,22V9.78H23V22A2.65,2.65,0,0,1,20.37,24.62Z"
                fill={props.mainColor || "#37474f"}
            />
            <path
                d="M18.15,12.24l-8,8L6.37,16.54A.75.75,0,1,0,5.3,17.6l4.28,4.27a.75.75,0,0,0,1.06,0l8.57-8.57a.75.75,0,1,0-1.06-1.06Z"
                fill={props.accentColor || "#8b6aac"}
            />
        </svg>
    );
};
