import {getCookie} from "@web2/request_utils";

import {IRequestIdService} from "../../services/create_request_id_service";
import {IServices} from "../../services/IServices";
import {isBrowser} from "../read_environment_variables";
import {IFetchOptions, isomorphicFetch, RequestMethod} from "./isomorphic_fetch";
import {
    parseResponseErrors,
    REQUEST_ABORTED_ERROR_NAME,
    RequestAbortedError,
    Response5xx,
    Response400,
    Response401,
    Response403,
    Response404,
    Response504,
    ResponseError,
    ResponseTimeoutError,
    ResponseUnknownError,
    ResponseUnknownStatus
} from "./response_error";

interface IRequestOptions {
    data: Record<string, any> | FormData;
    headers: Record<string, string>;
    credentials: "same-origin";
    preventJsonParse: boolean;
    timeout: number;
}

export const request = {
    create: (
        method: RequestMethod,
        url: string,
        requestId: string | null = null,
        options: Partial<IRequestOptions> = {},
        services?: Partial<IServices>
    ): Promise<any> => {
        // FormData API is not implemented in node, use only in browser
        const isFormData = isBrowser && options.data instanceof FormData;

        // prepare request data
        const headers: Record<string, string> = {
            ...(((services && services.serverRequestHeaders && services.serverRequestHeaders.fetchHeaders()) || {}) as Record<string, string>),
            ...(isFormData
                ? {
                      // leave header handling to browser and fetch library when FormData object is used
                  }
                : {
                      Accept: "application/json",
                      "Content-Type": "application/json"
                  })
        };

        // options
        const fetchOptions: IFetchOptions = {
            method,
            headers,
            credentials: options.credentials || "include"
        };

        // handle requestId - enable stalled responses for client/server requests
        const requestIdService: IRequestIdService | null = (services && services.requestId) || null;
        const abortControllerSignal = requestIdService?.resetOrStartAbortControllerSignal(requestId);

        if (abortControllerSignal) {
            fetchOptions.signal = abortControllerSignal;
        }

        if (options.data) {
            fetchOptions.body = isFormData ? (options.data as FormData) : JSON.stringify(options.data);
        }

        if (options.headers) {
            fetchOptions.headers = {...fetchOptions.headers, ...options.headers};
        }

        // request
        services && services.serverProfile && services.serverProfile.start(url);
        const mainPromise = isomorphicFetch(url, fetchOptions)
            .then((res: Response) => {
                services && services.serverProfile && services.serverProfile.stop(url);
                services && services.serverResponseHeaders && services.serverResponseHeaders.appendHeaders(res.headers);
                return res;
            })
            .then(handleErrors(url, services))
            .then((res: Response) => {
                if (res.status === 204) {
                    return null;
                }
                const preventJsonParse = options.preventJsonParse || false;
                return preventJsonParse ? res.text() : res.json();
            })
            .catch((err: any) => {
                if (err instanceof ResponseError) {
                    throw err;
                } else if (err?.name === REQUEST_ABORTED_ERROR_NAME) {
                    throw new RequestAbortedError(`Request stalled, fetch aborted: ${requestId}`);
                } else {
                    throw new ResponseUnknownError(err, url);
                }
            });
        if (options.timeout != null) {
            return Promise.race([
                new Promise((resolve, reject) => {
                    setTimeout(() => {
                        reject(new ResponseTimeoutError(url));
                    }, options.timeout);
                }),
                mainPromise
            ]);
        } else {
            return mainPromise;
        }
    }
};

const handleErrors = (url: string, services?: Partial<IServices>) => async (res: Response) => {
    const status = res.status;

    if (status === 400) {
        throw new Response400(res, url);
    }

    if (status === 401) {
        throw new Response401(res, url);
    }

    if (status === 403) {
        throw new Response403(res, url);
    }

    if (status === 404 || status === 410) {
        throw new Response404(res, url);
    }

    if (status === 504) {
        throw new Response504(res, url);
    }

    if (status >= 500) {
        throw new Response5xx(res, url);
    }

    if (!res.ok) {
        // status out of the range 200 - 299
        console.error("Unknown status: ", res);
        throw new ResponseUnknownStatus(res, url);
    }

    return res;
};

export function getRequest(services: Partial<IServices>, url: string, requestId?: string | null, headers?: Record<string, string>): Promise<any> {
    return parseResponseErrors(request.create(RequestMethod.GET, url, requestId, {headers}, services));
}

export function getExternalRequest(services: Partial<IServices>, url: string, timeout: number): Promise<any> {
    return parseResponseErrors(request.create(RequestMethod.GET, url, null, {credentials: "same-origin", timeout}, services));
}

export function postRequest(
    services: Partial<IServices>,
    url: string,
    data?: Record<any, any> | FormData,
    requestId?: string | null,
    requestOptions?: {preventJsonParse?: boolean}
): Promise<any> {
    const headers: Record<string, string> = {};

    // provide csrf token as special header alongside cookie value
    if (process.env.EXEC_ENV === "browser") {
        const csrf = getCookie("csrftoken");
        if (csrf) {
            headers["X-CSRFToken"] = csrf;
        }
    }

    return parseResponseErrors(
        request.create(
            RequestMethod.POST,
            url,
            requestId,
            {
                data,
                headers,
                ...requestOptions
            },
            services
        )
    );
}

export function putRequest(
    services: Partial<IServices>,
    url: string,
    data?: Record<any, any> | FormData,
    requestId?: string | null,
    requestOptions?: {preventJsonParse?: boolean}
): Promise<any> {
    const headers: Record<string, string> = {};

    // provide csrf token as special header alongside cookie value
    if (process.env.EXEC_ENV === "browser") {
        const csrf = getCookie("csrftoken");
        if (csrf) {
            headers["X-CSRFToken"] = csrf;
        }
    }

    return parseResponseErrors(
        request.create(
            RequestMethod.PUT,
            url,
            requestId,
            {
                data,
                headers,
                ...requestOptions
            },
            services
        )
    );
}

export function patchRequest(url: string, data?: Record<any, any>, requestId?: string): Promise<any> {
    const headers: Record<string, string> = {};

    if (process.env.EXEC_ENV === "browser") {
        const csrf = getCookie("csrftoken");
        if (csrf) {
            headers["X-CSRFToken"] = csrf;
        }
    }

    return parseResponseErrors(request.create(RequestMethod.PATCH, url, requestId, {data, headers}));
}

export function deleteRequest(url: string, data?: Record<string, any>, requestId?: string): Promise<any> {
    const headers: Record<string, string> = {};

    if (process.env.EXEC_ENV === "browser") {
        const csrf = getCookie("csrftoken");
        if (csrf) {
            headers["X-CSRFToken"] = csrf;
        }
    }

    return parseResponseErrors(request.create(RequestMethod.DELETE, url, requestId, {data, headers}));
}
