import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const RoundedCheckIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5 9C2.795 9 1 7.205 1 5C1 2.795 2.795 1 5 1C7.205 1 9 2.795 9 5C9 7.205 7.205 9 5 9ZM6.94 3.145L4 6.085L3.06 5.145C2.865 4.95 2.55 4.95 2.355 5.145C2.16 5.34 2.16 5.655 2.355 5.85L3.65 7.145C3.845 7.34 4.16 7.34 4.355 7.145L7.65 3.85C7.845 3.655 7.845 3.34 7.65 3.145C7.455 2.95 7.135 2.95 6.94 3.145V3.145Z" />
        </SvgIcon>
    );
};
