import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const RoomsIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "9.26mm"} height={props.height || "8.85mm"} viewBox="0 0 26.24 25.08">
            <path
                d="M26.19,15.11h0L23.89,8V.75A.75.75,0,0,0,23.14,0h-20a.76.76,0,0,0-.75.75V8L0,15.12H0a.78.78,0,0,0,0,.21v7.25a.76.76,0,0,0,.75.75h.66v1.75h1.5V23.33H23.33v1.75h1.5V23.33h.66a.76.76,0,0,0,.75-.75V15.33A.79.79,0,0,0,26.19,15.11Zm-24.69,1H24.74v5.75H1.5ZM22.39,1.5V7.25H20.21v1.4s0,.06,0,.1h2.37l1.89,5.83H1.78L3.67,8.75H6.23a.44.44,0,0,1,0-.1V7.25H3.84V1.5Z"
                fill={props.mainColor || "#37474f"}
            />
            <path d="M12.21,8.65s0,.06,0,.1h2a.44.44,0,0,1,0-.1V7.25h-2Z" fill={props.mainColor || "#37474f"} />
            <path
                d="M19.12,4.21H15.3A1.09,1.09,0,0,0,14.21,5.3V8.65a.44.44,0,0,0,0,.1,1.08,1.08,0,0,0,1.07,1h3.82a1.08,1.08,0,0,0,1.07-1s0-.07,0-.1V5.3A1.09,1.09,0,0,0,19.12,4.21Z"
                fill={props.accentColor || "#8b6aac"}
            />
            <path
                d="M7.3,9.74h3.82a1.08,1.08,0,0,0,1.07-1s0-.07,0-.1V5.3a1.09,1.09,0,0,0-1.09-1.09H7.3A1.09,1.09,0,0,0,6.21,5.3V8.65a.44.44,0,0,0,0,.1A1.08,1.08,0,0,0,7.3,9.74Z"
                fill={props.accentColor || "#8b6aac"}
            />
        </svg>
    );
};
