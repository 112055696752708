import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const FloorIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "9.98mm"} height={props.height || "9.79mm"} viewBox="0 0 28.28 27.76">
            <path
                d="M27.53,8.1H20.84a.75.75,0,0,0-.75.75v3.8H14.14a.76.76,0,0,0-.75.75v3.8H7.45A.75.75,0,0,0,6.7,18v3.8H.75A.75.75,0,0,0,0,22.5V27a.75.75,0,0,0,1.5,0V23.25h6a.76.76,0,0,0,.75-.75V18.7h5.94a.75.75,0,0,0,.75-.75v-3.8h5.95a.75.75,0,0,0,.75-.75V9.6h5.94a.75.75,0,0,0,0-1.5Z"
                fill={props.mainColor || "#37474f"}
            />
            <path
                d="M1.33,18.26a.76.76,0,0,0,.43-.14L3.35,17v2.28a.75.75,0,1,0,1.5,0V15.92L5,15.81l5.29-3.59v2.49a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5V11.54L17,7.67V10.2a.5.5,0,0,0,1,0V7l5.44-3.7V5.63a.75.75,0,0,0,1.5,0V2.26l1.27-.9a.76.76,0,0,0,.17-1A.76.76,0,0,0,25.32.14L23.77,1.25h0L4.16,14.57h0l-.47.33,0,0-2.75,2A.75.75,0,0,0,.71,18,.77.77,0,0,0,1.33,18.26Z"
                fill={props.accentColor || "#8b6aac"}
            />
        </svg>
    );
};
