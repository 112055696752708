import * as React from "react";

interface IProps {
    label?: string | undefined;
    htmlFor?: string | undefined;
}

export const FieldLabel: React.FunctionComponent<IProps> = (props: IProps) => {
    if (props.label) {
        return <label htmlFor={props.htmlFor}>{props.label}</label>;
    }

    return null;
};
