import {Dispatch} from "redux";
import {safelyParsePage} from "@web2/string_utils";

import {apiLink} from "../../app/routes/api_link";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {appendQueryString} from "../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {PropertyType} from "../../offer/utils/constants_offer";
import {IndividualOfferStatus} from "../constants";

export const fetchIndividualOffersActionTypes = createRequestActionTypes({name: "fetch-offers", type: "GET", view: "individual-offer-list"});

export interface IndividualOfferListOffer {
    id: string; // uuid, required for status changing actions: edit, publish etc.
    title: string;
    created_at: string;
    modified_at?: string | null;
    status: IndividualOfferStatus;
    property_type: PropertyType;
    slug: string;
}

export const INDIVIDUAL_OFFERS_LIST_PAGE_SIZE = 20;

export const fetchIndividualOffersAtRoute =
    (services: Partial<IServices>, route: IRouteState<{}>) =>
    async (dispatch: Dispatch): Promise<IndividualOfferListOffer[]> => {
        const page = safelyParsePage(route.query.page);
        return await dispatch(fetchIndividualOffers(services, {page}));
    };

export const fetchIndividualOffers =
    (services: Partial<IServices>, params: {page: number}) =>
    async (dispatch: Dispatch): Promise<any> => {
        dispatch({type: fetchIndividualOffersActionTypes.start});

        const url = appendQueryString(apiLink.offers.individual.base({})(null), {
            page_size: INDIVIDUAL_OFFERS_LIST_PAGE_SIZE,
            ...params
        });

        return getRequest(services, url).then((result) => {
            dispatch({type: fetchIndividualOffersActionTypes.success, result});
            return result;
        });
    };
