/**
 * Provides debounce functionality for given request action.
 */
import {Dispatch} from "redux";

type IRequestAction = (...args: any[]) => (dispatch: Dispatch, getState: () => any) => Promise<any>;

export const createDebouncedAction = (requestAction: IRequestAction, debounceTime: number) => {
    let prevTimeout = 0;

    return {
        action:
            (...args: any[]) =>
            (dispatch: Dispatch, getState: () => any): Promise<any> => {
                // invalidate previous timeout
                if (prevTimeout) {
                    clearTimeout(prevTimeout);
                    prevTimeout = 0;
                }
                // call actual action
                return new Promise<any>((resolve) => {
                    prevTimeout = window.setTimeout(() => {
                        prevTimeout = 0;
                        requestAction(...args)(dispatch, getState).then((res: any) => {
                            resolve(res);
                        });
                    }, debounceTime);
                });
            },
        clear:
            () =>
            (dispatch: Dispatch, getState: () => any): Promise<any> => {
                clearTimeout(prevTimeout);
                return Promise.resolve();
            }
    };
};

/**
 * Debounced function
 */

export const createDebouncedFunction = (requestAction: (...args: any[]) => Promise<any>, debounceTime: number) => {
    let prevTimeout = 0;

    return {
        action: (...args: any[]): Promise<any> => {
            // invalidate previous timeout
            if (prevTimeout) {
                clearTimeout(prevTimeout);
                prevTimeout = 0;
            }
            // call actual action
            return new Promise<any>((resolve) => {
                prevTimeout = window.setTimeout(() => {
                    prevTimeout = 0;
                    requestAction(...args).then((res: any) => {
                        resolve(res);
                    });
                }, debounceTime);
            });
        },
        clear: (): Promise<any> => {
            clearTimeout(prevTimeout);
            return Promise.resolve();
        }
    };
};
