import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const PriceTagPlainIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "8.13mm"} height={props.height || "9.63mm"} viewBox="0 0 23.04 27.29">
            <path
                d="M22.93,3.89a.73.73,0,0,0-.23-.54.81.81,0,0,0-.56-.21l-10.72.58A.75.75,0,0,0,10.9,4L9.76,5.2a.75.75,0,0,0,.05,1.06.76.76,0,0,0,1.06,0l.93-1,9.64-.52.1,9.89-10,10.91a1,1,0,0,1-.68.31.87.87,0,0,1-.69-.27l-8.32-8a1,1,0,0,1,0-1.44l6.1-6.63a.76.76,0,0,0,0-1.06.75.75,0,0,0-1.06.05L.66,15.09a2.54,2.54,0,0,0,.11,3.53l8.32,8a2.41,2.41,0,0,0,1.7.69h.08a2.41,2.41,0,0,0,1.73-.8L22.84,15.37a.79.79,0,0,0,.2-.52Z"
                fill={props.mainColor || "#37474f"}
            />
            <path
                d="M9.19,9.55A7.59,7.59,0,0,0,13.82,11c.33,0,.66,0,1-.06a2.66,2.66,0,0,0,.4.5,2.72,2.72,0,0,0,1.87.75h.09a2.66,2.66,0,0,0,1.91-.88A2.81,2.81,0,0,0,19,7.42a2.66,2.66,0,0,0-2-.75,2.69,2.69,0,0,0-1.91.87,2.82,2.82,0,0,0-.73,2,6.35,6.35,0,0,1-4.25-1.13,3.2,3.2,0,0,1-1.3-2.14c-.27-2.18,2-4.27,5-4.66a6.47,6.47,0,0,1,4.69,1.09.77.77,0,0,0,1.06-.13.76.76,0,0,0-.14-1.06A7.88,7.88,0,0,0,13.66.08C9.79.57,7,3.41,7.32,6.4A4.72,4.72,0,0,0,9.19,9.55Zm7-1a1.19,1.19,0,0,1,.85-.39h0a1.24,1.24,0,0,1,.83.33,1.31,1.31,0,0,1,0,1.81h0a1.2,1.2,0,0,1-1.72,0A1.29,1.29,0,0,1,16.2,8.56Z"
                fill={props.className || "#8b6aac"}
            />
        </svg>
    );
};
